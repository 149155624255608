<template>
  <div>
    <page-heading :heading="$t('categories')">
      <template v-slot:actions>
        <app-button :label="$t('create')" @click="openCreateModal()"></app-button>
      </template>
    </page-heading>

    <form-open class="grid lg:grid-cols-4 grid-cols-2 gap-4 mb-10" @submit="filter">
      <form-text
        :label="$t('search')"
        :required="false"
        :show-validation-label="false"
        icon="search"
        input-id="search"
        v-model="query.filter.name"
      />
      <app-button class="hidden"/>
    </form-open>

    <loading-wrapper>
      <app-table :items="items">
        <template
          v-slot:headings
        >
          <app-th :heading="$t('id')"/>
          <app-th-sortable :label="$t('model.asset_category.name')" name="name" :sort="query.sort" @updateSort="updateSort"/>
          <app-th :heading="$t('model.asset_category.assets')"/>
          <app-th/>
        </template>

        <template
          v-slot:row="{ item }"
        >
          <app-td>
            <a href="#" class="link font-bold" @click.prevent="openEditModal(item.id)">{{ item.id }}</a>
          </app-td>
          <app-td>
            <a href="#" @click.prevent="openEditModal(item.id)" class="link">{{ item.name }}</a>
          </app-td>
          <app-td>
            <template v-if="$can('manage_assets')">
              <a href="#" @click.prevent="goToAssets(item.id)">
                <badge :label="item.assets_count"/>
              </a>
            </template>
            <template v-else>
              <badge :label="item.assets_count"/>
            </template>
          </app-td>
          <app-td>
            <app-options>
              <app-option-edit @click="openEditModal(item.id)"/>
              <app-option-delete @click="openDeleteModal(item.id)"/>
            </app-options>
          </app-td>
        </template>
      </app-table>
      <pagination
        :meta="meta"
        :page="query.page"
        v-if="meta"
        @pageUpdated="updatePage"
      />
    </loading-wrapper>

    <slideover :active="modals.createEdit" @close="closeModal('createEdit')" @submit="submit"
      :title="modelId ? $t('edit') : $t('create')"
    >
      <debug>{{ model }}</debug>
      <form-text :form-id="formId" input-id="name" form-error-id="name" :label="$t('model.asset_category.name')" v-model="model.name"/>

      <template v-slot:buttons>
        <app-button
          :disabled="loading"
          :label="$t(modelId ? 'update' : 'create')"
          :loading="loading"
        />
      </template>
    </slideover>

    <modal-delete
      :active="modals.delete"
      :text="$t('delete_thing_text', { thing: model.name })"
      :title="$t('delete')"
      @close="closeModal('delete')"
      @delete="deleteModel(modelId)"
    />
  </div>
</template>

<script>
import ApiAssetCategoryService from '@/services/api/asset_category';
import Common from '@/mixins/common';

export default {
  data() {
    return {
      formId: 'createEditAssetCategory',
      items: [],
      meta: {},
      modals: {
        createEdit: false,
        delete: false,
      },
      model: this.emptyModel(),
      modelId: false,
      query: {
        filter: {},
        page: 1,
        sort: [
          'name',
        ],
        ...this.$route.query,
      },
    };
  },
  mixins: [
    Common,
  ],
  mounted() {
    this.getListings();
  },
  methods: {
    deleteModel(modelId) {
      ApiAssetCategoryService.deleteAssetCategory(modelId).then(() => {
        this.closeModals();
        this.getListings();
      }).catch(() => {});
    },
    emptyModel() {
      return {};
    },
    filter() {
      this.query.page = 1;
      this.getListings();
    },
    getAndSetModel(modelId) {
      this.resetModel();

      return ApiAssetCategoryService.getAssetCategory(modelId).then((response) => {
        const { data } = response.data;

        this.modelId = modelId;
        this.model = this.resourceToModel(data);
      }).catch(() => {});
    },
    getListings() {
      ApiAssetCategoryService.getAssetCategories({
        params: {
          ...this.query,
          include: [
            'assetsCount',
          ],
        },
      }).then((response) => {
        const { data, meta } = response.data;

        this.items = data;
        this.meta = meta;
      }).catch(() => {});
    },
    goToAssets(modelId) {
      this.$router.push({
        name: 'assets',
        query: {
          filter: {
            category: [
              modelId,
            ],
          },
        },
      });
    },
    openCreateModal() {
      this.resetModel();
      this.openModal('createEdit');
    },
    openDeleteModal(modelId) {
      this.getAndSetModel(modelId).then(() => {
        this.openModal('delete');
      });
    },
    openEditModal(modelId) {
      this.getAndSetModel(modelId).then(() => {
        this.openModal('createEdit');
      });
    },
    resetModel() {
      this.modelId = null;
      this.model = this.emptyModel();
    },
    resourceToModel(resource) {
      return {
        name: resource.name,
      };
    },
    routeQueryUpdated() {
      this.getListings();
    },
    submit() {
      if (this.modelId) {
        ApiAssetCategoryService.updateAssetCategory(this.modelId, this.model, {
          formId: this.formId,
          showMessage: true,
        }).then(() => {
          this.getListings();
          this.closeModals();
        }).catch(() => {});
      } else {
        ApiAssetCategoryService.createAssetCategory(this.model, {
          formId: this.formId,
          showMessage: true,
        })
          .then((response) => {
            const { data } = response.data;

            this.getAndSetModel(data.id);
            this.getListings();
            this.closeModals();
          })
          .catch(() => {});
      }
    },
  },
};
</script>
